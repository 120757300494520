@import "../../scss/base.scss";

.container {
  height: 65px;
  @include mobile {
    height: 50px;
  }

  &.hasBanner {
    height: 105px;
    @include mobile {
      height: 90px;
    }
  }

  .header {
    box-sizing: border-box;
    height: 65px;
    width: 100%;
    top: 0;
    border-bottom: rgb(212, 213, 226) 1px solid;
    padding: 0 30px;
    position: fixed;
    background-color: white;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;

    &.isBannerOpen {
      top: 40px;
      @include mobile{
        top: 50px
      }
    }

    @include mobile {
      padding: 0 15px;
      height: 50px;
      justify-content:baseline;
    }

    //Section logo + options
    .sections1 {
      display: flex;
      align-items: center;
      .mawoo {
        width: 154px;
        min-width: 100px;
        height: 34px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("/mawoo-green.svg");
        cursor: pointer;
        @include mobile {
          height: 30px;
        }
      }
      .mawooWithoutLogin {
        width: 154px;
        min-width: 100px;
        height: 34px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("/mawoo-green.svg");
        cursor: pointer;
        @include mobile {
          height: 43px;
          min-width: 110px;
          width: 43px;
          margin-right: 20px;
          background-image: url("/mawoo-logo-mobile.svg");
        }
        @media (max-width: 365px) {
         margin-right: 5px;
    
        }
      }
      .financing {
        margin:0 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        text-decoration: none;
        @media (min-width: 300px) {
          margin:0 ;
         }
        >span{
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
        font-size: 12px;
        line-height: 15px;
        text-align: start;
        color: #393939;
        font-weight: bold;
        @media (max-width: 365px) {
        
          font-size: 10px;
         }
        }
        
      }

      .logo {
        width: 38px;
        height: 28px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("/mawoo_brandmark.svg");
        cursor: pointer;
      }

      .options {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;

        @include tablet {
          display: none;
        }

        a {
          text-decoration: none;
          height: 100%;

          .section {
            position: relative;
            height: 65px;
            margin: 0 15px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .title {
              font-family: Circular Std Book, Helvetica, Arial, sans-serif;
              font-weight: bold;
              font-size: 16px;
              text-align: center;
              color: #393939;
              white-space: nowrap;
            }

            .bar {
              width: 100%;
              height: 5px;
              position: absolute;
              bottom: -1px;
              border-radius: 2.5px;
              background-color: #00ad66;
            }

            .bar2 {
              width: 100%;
              display: none;
              position: absolute;
              background: transparent;
            }
          }

          .section:hover .bar2 {
            display: block;
            height: 5px;
            bottom: -1px;
            border-radius: 2.5px;
            background-color: #a6e8cd;
          }
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        //height: 80px;

        @include tablet {
          display: none;
        }

        .buttonContainer2 {
          height: 100%;
        }
        .buttonContainer {
          > button {
            width: 120px;
          }
        }
        .buttonContainer,
        .buttonContainer2 {
          position: relative;
          

          > button {
            margin-left: 10px;
          }

          .modal2 {
            right: -56px;
            top: 80px;
          }
          .modal3 {
            right: -60px;
            top: 80px;
            width: 190px;
            height: 350px;
            padding: 25px 0 25px 15px;
          }
         

          .modal {
            right: 0;
            top: 65px;
          }

          .modal,
          .modal2,
          .modal3,
          .modalVets {
            box-sizing: border-box;
            width: 215px;
            padding: 25px;
            position: absolute;
            display: flex;
            flex-direction: column;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #a6e8cd;

            .arrow,
            .arrow2,
            .arrow3,
            .arrowVets {
              box-sizing: border-box;
              width: 12px;
              height: 12px;
              border-radius: 4px 0 0 0;
              transform: rotate(45deg);
              border-top: 1px solid #a6e8cd;
              border-left: 1px solid #a6e8cd;
              background: #ffffff;
              position: absolute;
              top: -7px;
              right: 30px;
            }

            .arrow2,.arrowVets {
              right: 98px;
            }
            .arrow3 {
              right: 100px;
            }

            .option {
              font-size: 16px;
            }

            .option2 {
              font-size: 16px;
              margin-bottom: 20px;
              > div {
                margin-left: 5px;
              }
            }

            .option,
            .option2,
            .option3 {
              width: 120%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-family: Circular Std Book, Helvetica, Arial, sans-serif;

              color: #393939;
              cursor: pointer;

              .icon {
                height: 10px;
                width: 10px;
                background-image: url("/next-black.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
            .option3 {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 20px;
              justify-content: flex-start;
              align-items: baseline;
              > div {
                margin-left: 5px;
              }
            }
           
            .option:hover,
            .option2:hover,
            .option3:hover {
              color: #00ad66;

              .icon {
                background-image: url("/next-green.svg");
              }
            }
          }

          .modalVets{
            right: -62px;
            top: 80px;
            height: 270px;
            padding: 25px 20px 0;
            >div{
              >a{
                height: auto;
              }

            }
          }
        }
      }
    }

    // Section buttons + icons
    .sections2 {
      display: flex;
      justify-content: space-between;

      .actionIcons {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin: 0 10px;
        .financing {
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #393939;
          font-weight: bold;
          white-space: nowrap;
          margin: 0 10px;
          text-decoration: none;
          display: flex;
          align-items: center;
          >img{
            margin-right: 5px;
          }
        }
        .magnifying,
        .phone {
          margin: 0 10px;
          img {
            width: 23px;
            height: 23px;
          }
        }
      }

      .actions {
        height: 100%;
        display: flex;

        position: relative;
        @include tablet {
          align-items: center;
        }

        > a {
          margin-right: 15px;
        }

        .keypad {
          box-sizing: border-box;
          height: 50px;
          margin-left: 15px;
          padding-left: 15px;
          display: flex;
          align-items: center;
          border-left: 1px solid rgba(57, 57, 57, 0.4);

          @include tablet {
            display: none;
          }

          a {
            .fav,
            .appNot,
            .application,
            .pet {
              width: 27px;
              height: 27px;
              margin: 15px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              cursor: pointer;
              font-size: 12px;
            }

            .fav {
              background-image: url("/header/favorites.svg");
            }
            .fav:hover {
              background-image: url("/header/hover/heart.svg");
            }
            .appNot {
              background-image: url("/header/appNotif.svg");
            }
            .appNot:hover {
              background-image: url("/header/hover/application-breeder.svg");
            }
            .application {
              background-image: url("/header/applications.svg");
            }
            .application:hover {
              background-image: url("/header/hover/application.svg");
            }
            .pet {
              background-image: url("/header/pets.svg");
            }
            .pet:hover {
              background-image: url("/header/hover/paw.svg");
            }
          }

          .profile {
            display: flex;
            align-items: center;
            margin: 15px;
            justify-content: center;

            .drop {
              cursor: pointer;
              &.up {
                width: 50px;
                height: 50px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("/header/user-up.svg");
              }
              &.up:hover {
                background-image: url("/header/hover/user-up.svg");
              }

              &.down {
                width: 50px;
                height: 50px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("/header/hover/user-down.svg");
              }
              &.down:hover {
                background-image: url("/header/user-down.svg");
              }
            }
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          //height: 80px;

          @include tablet {
            display: none;
          }

          .buttonContainer2 {
            height: 100%;
          }
          .buttonContainer {
            > button {
              width: 120px;
            }
          }
          .buttonContainer,
          .buttonContainer2 {
            position: relative;
            margin: 0 10px 0 0;
            > button {
              margin-left: 10px;
            }

            .modal2 {
              right: -40px;
              top: 80px;
            }
            .modal3 {
              right: -40px;
              top: 80px;
              width: 190px;
              height: 350px;
              padding: 25px 0 25px 15px;
            }

            .modal {
              right: 0;
              top: 65px;
            }

            .modal,
            .modal2,
            .modal3 {
              box-sizing: border-box;
              width: 215px;
              padding: 25px;
              position: absolute;
              display: flex;
              flex-direction: column;
              background: #ffffff;
              border-radius: 8px;
              border: 1px solid #a6e8cd;

              .arrow,
              .arrow2,
              .arrow3 {
                box-sizing: border-box;
                width: 12px;
                height: 12px;
                border-radius: 4px 0 0 0;
                transform: rotate(45deg);
                border-top: 1px solid #a6e8cd;
                border-left: 1px solid #a6e8cd;
                background: #ffffff;
                position: absolute;
                top: -7px;
                right: 30px;
              }

              .arrow2 {
                right: 98px;
              }
              .arrow3 {
                right: 80px;
              }

              .option {
                font-size: 16px;
              }

              .option2 {
                font-size: 16px;
                margin-bottom: 20px;
              }

              .option,
              .option2,
              .option3 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: Circular Std Book, Helvetica, Arial, sans-serif;

                color: #393939;
                cursor: pointer;

                .icon {
                  height: 10px;
                  width: 10px;
                  background-image: url("/next-black.svg");
                  background-size: contain;
                  background-position: center;
                  background-repeat: no-repeat;
                }
              }
              .option3 {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 20px;
                justify-content: flex-start;
                align-items: baseline;
                > div {
                  margin-left: 5px;
                }
              }

              .option:hover,
              .option2:hover,
              .option3:hover {
                color: #00ad66;

                .icon {
                  background-image: url("/next-green.svg");
                }
              }
            }
          }
        }
        .btnLogin {
          font-weight: bold;
          line-height: 18px;
          font: 14px Circular Std Bld, Helvetica, Arial, sans-serif;
          color: #00ad66;
          margin-right: 15px;
        }

        // Modal Login
        .background {
          height: 100%;
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background-color: rgb(57, 57, 57, 0.4);
          z-index: 10;
          .modal {
            box-sizing: border-box;
            width: 100%;
            height: 250px;
            padding: 30px;
            background: #ffffff;
            border-radius: 20px 20px 0px 0px;

            .title {
              padding-bottom: 15px;
              margin-bottom: 25px;
              font-family: Circular Std Book;
              font-weight: bold;
              font-size: 20px;
              color: #393939;
              border-bottom: 1px solid rgb(0, 173, 102, 0.6);
            }

            .option {
              width: fit-content;
              display: flex;
              align-items: center;
              font-family: Circular Std Book;
              font-weight: bold;
              font-size: 16px;
              color: #393939;
              cursor: pointer;

              > div:first-child {
                margin-right: 10px;
              }

              .icon {
                height: 10px;
                width: 10px;
                background-image: url("/next-black.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
              }
            }

            .option:hover {
              color: #00ad66;

              .icon {
                background-image: url("/next-green.svg");
              }
            }
          }
        }
        .burger {
          display: none;

          @include tablet {
            display: flex;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            margin-left: 5px;

            &.open {
              height: 18px;
              width: 18px;
              background-image: url("/Menu.svg");
            }

            &.close {
              height: 18px;
              width: 18px;
              background-image: url("/close-black.svg");
            }
          }
        }
      }
    }
  }
}

//Section Puppies Options
.puppiesOption {
  height: 80px;

  .section {
    position: relative;
    height: 80px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .title {
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #393939;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .lineHover {
      width: 100%;
      display: none;
      position: absolute;
      background: transparent;
      margin-top: 60px;
    }
  }

  .section:hover .lineHover {
    display: block;
    height: 5px;
    bottom: 7px;
    border-radius: 2.5px;
    background-color: #a6e8cd;
  }

  .line {
    height: 5px;
    bottom: 1px;
    margin-left: 8px;
    margin-top: -12px;
    border-radius: 2.5px;
    background-color: #a6e8cd;
  }
}

//Section MawooPets & Vets Options
.btnAboutUs, .btnVets {
  height: 80px;

  .sectionAU, .sectionVets {
    position: relative;
    height: 100%;
    margin: 0 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .titleAU, .titleVets {
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #393939;
      white-space: nowrap;
    }

    .barAU, .barVets {
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: 1px;
      border-radius: 2.5px;
      background-color: #00ad66;
      top: 68px;
    }
    
    .barVets{
      width:76px;
    }
    .lineAUHover, .lineVetsHover {
      width: 100%;
      display: none;
      position: absolute;
      background: transparent;
      margin-top: 60px;
    }
    .lineVetsHover{
      width: 100px;
    }
  }

  .sectionAU:hover .lineAUHover,.lineVetsHover {
    display: block;
    height: 5px;
    bottom: -5px;
    border-radius: 2.5px;
    background-color: #a6e8cd;
  }

  .lineAU, .lineVets {
    width: 70px;
    height: 5px;
    bottom: 1px;
    margin-left: 14px;
    margin-top: -12px;
    border-radius: 2.5px;
    background-color: #a6e8cd;
  }
  .lineVets{
    width: 76px
  }
}

.closeArea {
  width: 100vw;
  position: absolute;
  background-color: #00000011;
  top: -5px;
  bottom: 0;
  right: -30px;
  z-index: -1;
  height: 100vh;
  backdrop-filter: blur(5px);
}
