@import "../../../../scss/base.scss";

.banner {

  display: flex !important;
  align-items: center !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
  position: fixed !important;
  bottom: -60px !important;
  height: 65px !important;
  left: 0px !important;
  right: 0px !important;
  padding: 0 0px 60px 25px;
  contain: paint !important;
  background-color: white;
  z-index: 10 !important;

  &.showBanner {
    transition: all 500ms ease-out;
  }

  &.hidden {
    transition: all 500ms ease-out;
    transform: translateY(100%) !important;
    visibility: hidden !important;

  }

  .more {
    width: 25%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

}

//MODAL
.modal {

  box-sizing: border-box;
  contain: style !important;
  width: 130px;
  bottom: 80px !important;
  left: 215px !important;
  padding: 10px 20px 10px 20px;
  position: fixed !important;
  z-index: 10 !important;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 4px 4px 10px rgb(57 57 57 / 10%);

  @include tablet{
    left: 72vw !important;
  }
  @include mobile{
    left: 240px !important;
    bottom: 70px !important;
  }

  &.hidden {
    visibility: hidden !important;

  }


  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;

    .payouts,
    .settings {
      display: flex;
      flex-direction: row;
      font: 14px Circular Std Book, Helvetica, Arial, sans-serif;
      color: #393939;
      cursor: pointer;
      margin: 5px;

      >img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

  }
}

.item {
  cursor: pointer;

  @include tablet {
    width: 25%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .fav,
  .application,
  .pet,
  .more,
  .dashboard,
  .setting {
    height: 70px;
    width: 70px;
    margin-right: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

  }

  .fav {
    background-image: url('/subMenu/favourite.svg');
  }

  .application {
    background-image: url('/subMenu/application.svg');
  }

  .pet {
    background-image: url('/subMenu/pets.svg');
  }


  .more {
    background-image: url('/subMenu/more-green.svg');
  }

  .dashboard {
    background-image: url('/subMenu/dashboard.svg');
  }

  .setting {
    background-image: url('/subMenu/settings.svg');
  }

}
.item:hover{
  .setting {
    background-image: url('/subMenu/settings-green.svg');
  }

  .dashboard {
    background-image: url('/subMenu/dashboard-green.svg');
  }

  .more {
    background-image: url('/subMenu/more.svg');
  }
 

  .pet {
    background-image: url('/subMenu/pets-green.svg');
  }

  .application {
    background-image: url('/subMenu/application-green.svg');
  }

  .fav {
    background-image: url('/subMenu/favourite-green.svg');
  }
}
.active {
 
  .setting {
    background-image: url('/subMenu/settings-green.svg');
  }

  .dashboard {
    background-image: url('/subMenu/dashboard-green.svg');
  }

  .more {
    background-image: url('/subMenu/more.svg');
  }
 

  .pet {
    background-image: url('/subMenu/pets-green.svg');
  }

  .application {
    background-image: url('/subMenu/application-green.svg');
  }

  .fav {
    background-image: url('/subMenu/favourite-green.svg');
  }
}